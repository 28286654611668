// extracted by mini-css-extract-plugin
export var downloadIcon = "resource-module--downloadIcon--f96f1";
export var downloadIconContainer = "resource-module--downloadIconContainer--200c2";
export var downloadText = "resource-module--downloadText--4d35d";
export var linkTo = "resource-module--linkTo--2ac00";
export var readMoreButton = "resource-module--readMoreButton--58b41";
export var readMoreIconContainer = "resource-module--readMoreIconContainer--61ba3";
export var resourceCard = "resource-module--resourceCard--e009a";
export var resourceCardContent = "resource-module--resourceCardContent--5268d";
export var resourceDescription = "resource-module--resourceDescription--6dc26";
export var resourceImage = "resource-module--resourceImage--b1821";
export var resourceTitle = "resource-module--resourceTitle--a5b40";