import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Footer from "../components/Layout/Footer";
import ResourceCard from "../components/Resources";
import * as styles from '../components/Resources/resource.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight, faX } from "@fortawesome/free-solid-svg-icons";
import { pdfjs, Document, Page } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { useEffect } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


export default function ResourcesPage({ data }) {
  const categories = data.allSanityResourceCategories.nodes;
  const resources = data.allSanityResources.nodes;
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    const filteredCategories = categories.filter(category => resources.some(resource => resource.category.id === category.id));
    setFilteredCategories(filteredCategories.map(category => ({ ...category, expanded: false })));
  }, [categories, resources]);

  const handleExpandCategory = (categoryId) => {
    setFilteredCategories(prevCategories =>
      prevCategories.map(category =>
        category.id === categoryId ? { ...category, expanded: !category.expanded } : category
      )
    );
  };

  const handleClickReadMore = (resource) => {
    const url = resource.resourceFile.asset.url;
    setPdfUrl(url);
    setShowPdf(true);
  };

  const handleClosePdf = () => {
    setShowPdf(false);
    setPdfUrl('');
  };

  useEffect(() => {
    const disableRightClick = (event) => {
        event.preventDefault();
    };

    // Add event listener only to the Document component
    document.getElementById('pdfDocument')?.addEventListener('contextmenu', disableRightClick);

    // Clean up the event listener when component unmounts
    return () => {
        document.getElementById('pdfDocument')?.removeEventListener('contextmenu', disableRightClick);
    };
  }, [showPdf]);

  return (
      <>
          <main className="site-container" style={{ padding: "2rem" }}>
              <h1 style={{ marginBottom: "2rem", fontSize: "2rem", textAlign: "center", textTransform: "uppercase" }}>
                  GOA RESOURCES
              </h1>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <p style={{ marginBottom: "2rem", fontSize: "1.5rem", textAlign: "center", fontWeight: "bold", width: '80%' }}>
                      Empower yourself with knowledge and stay informed about
                      the latest developments in the South African cannabis
                      industry through our Resource Center.
                  </p>
              </div>

              <div>
                  {filteredCategories
                      .sort((a, b) => a.order - b.order)
                      .map((category, index) => (
                          <div key={category.id}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={() => handleExpandCategory(category.id)}>
                                  <FontAwesomeIcon icon={faChevronRight} style={{ transform: category.expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                                </button>
                                <h1 style={{ textTransform: 'uppercase', fontSize: '1.5rem', marginLeft: '1rem' }}>
                                  <span style={{ marginRight: '2rem' }}>{index + 1}.</span>{category.title}
                                </h1>
                              </div>
                              <div style={{ display: "grid", gridTemplateColumns: category?.images ? "repeat(auto-fill, minmax(300px, 1fr))" : "1fr", gap: category?.images ? "4rem" : "2rem", margin: '2rem' }}>
                                  {category.expanded && resources
                                    .filter(resource => resource.category.id === category.id)
                                    .map(resource => (
                                        <React.Fragment key={resource._id}>
                                            {resource.category.images ? (
                                                <div>
                                                    <ResourceCard resource={resource} />
                                                </div>
                                            ) : (
                                                <div style={{ width: '50vw', marginLeft: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <p>&bull; {resource.title}</p>
                                                    <button onClick={() => handleClickReadMore(resource)} className={styles.readMoreButton}>
                                                        <p>READ MORE</p>
                                                        <FontAwesomeIcon icon={faArrowRight} className={styles.readMoreIconContainer} />
                                                    </button>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                              </div>
                          </div>
                      ))}
              </div>
          </main>

          {/* PDF Viewer */}
          {showPdf && (
              <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem', zIndex: 999 }}>
                  {pdfUrl ? <PdfViewer pdfUrl={pdfUrl} onClose={handleClosePdf} onLoad={() => setIsLoaded(true)} /> : <p>Loading...</p>}
              </div>
          )}

          <Footer />
      </>
  );
}

export const PdfViewer = ({ pdfUrl, onClose, onLoad }) => {
  const [numPages, setNumPages] = useState(null);

  return (
    <div
      id="pdfDocument"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        height: "90vh",
        border: "none",
        overflow: "auto",
      }}
    >
      <button 
        style={{ 
          position: "absolute",
          top: "1rem",
          right: "1rem",
          alignSelf: "flex-end" 
        }} 
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faX} />
      </button>
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        renderMode="canvas"
      >
        {[...Array(numPages).keys()].map((pageNumber) => (
          <Page 
            key={pageNumber + 1} 
            pageNumber={pageNumber + 1} 
            scale={1.4}  
          />
        ))}
      </Document>
    </div>
  );
};


  export const query = graphql`
  query ResourcesPageQuery {
    allSanityResourceCategories {
      nodes {
        id
        title
        order
        images
        downloadable
      }
    }
    allSanityResources(sort: { fields: category___order, order: ASC }) {
      nodes {
        _id
        title
        description
        featuredImage {
          asset {
            gatsbyImageData(aspectRatio: 1.5)
          }
        }
        resourceFile {
          asset {
            url
          }
        }
        url
        category {
          id
          title
          order
          images
          downloadable
        }
      }
    }
  }
`;