import React, { useEffect, useState } from 'react';
import * as styles from './resource.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faX } from "@fortawesome/free-solid-svg-icons";
import { pdfjs, Document, Page } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();

export default function ResourceCard({ resource }) {
    const [ showPdf, setShowPdf ] = React.useState(false);
    const [ pdfUrl, setPdfUrl ] = React.useState('');
    const [ isLoaded, setIsLoaded ] = React.useState(false);

    const DownloadButton = (fileUrl, fileName) => {
        const handleDownload = async (fileUrl, fileName) => {

        // format filename to include extension from fileUrl
        const formattedFileName = fileUrl.fileName.replace(/\s/g, '_') + '.' + fileUrl.fileUrl.split('.').pop();

        // Fetch the file
        const response = await fetch(fileUrl.fileUrl);
        const blob = await response.blob(); // Create a blob from the response
    
        // Create an anchor element and trigger download
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', formattedFileName); // Set the file name
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        };
      
        return (
            <div style={{ display: "flex", alignItems: "center", padding: "0", transform: "translateX(-1.2rem)", cursor: 'pointer'}} onClick={() => handleDownload(fileUrl, fileName)}>
                <div className={styles.downloadIconContainer}>
                    <svg
                        className={`${styles.downloadIcon} ${styles.svgIcon}`}
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                        fill="currentColor"
                    >
                    <   path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                    </svg>
                </div> 
                <p className={styles.downloadText}>DOWNLOAD</p>
            </div>
        );
      };

    const handleClickReadMore = (resource) => {
        const url = resource.resourceFile.asset.url;
        setPdfUrl(url);
        setShowPdf(true);
      };
  
      const handleClosePdf = () => {
        setShowPdf(false);
        setPdfUrl('');
      };
  
      useEffect(() => {
        const disableRightClick = (event) => {
            event.preventDefault();
        };
  
        // Add event listener only to the Document component
        document.getElementById('pdfDocument')?.addEventListener('contextmenu', disableRightClick);
  
        // Clean up the event listener when component unmounts
        return () => {
            document.getElementById('pdfDocument')?.removeEventListener('contextmenu', disableRightClick);
        };
    }, [showPdf]);

  return (
    <div className={styles.resourceCard}>
        {resource.category.images && resource.featuredImage && (
            <img
            src={resource.featuredImage.asset.gatsbyImageData.images.fallback.src}
            alt={resource.title}
            className={styles.resourceImage}
            />
        )}

        <div className={styles.resourceCardContent}>
            <h2 className={styles.resourceTitle}>{resource.title}</h2>

            <div className={styles.resourceDescription}>
                <p>{resource.description}</p>
            </div>

            {resource.category.downloadable ? (
            <div>
                {resource.resourceFile ? (
                    <DownloadButton 
                        fileUrl={resource.resourceFile.asset.url}
                        fileName={resource.title}
                    />
                ) : (
                    <a
                        href={resource.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.linkTo}
                    >
                        VIEW
                    </a>
                )}
            </div>
            ) : (
                <button onClick={() => handleClickReadMore(resource)} className={styles.readMoreButton}>
                <p>READ MORE</p>
                <FontAwesomeIcon icon={faArrowRight} className={styles.readMoreIconContainer}/>
              </button>
            )}
      </div>

        {/* PDF Viewer */}
        {showPdf && (
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem', zIndex: 999 }}>
                {pdfUrl ? <PdfViewer pdfUrl={pdfUrl} onClose={handleClosePdf} onLoad={() => setIsLoaded(true)}/> : <p>Loading...</p>}
            </div>
        )}
    </div>
  );
}

// export const PdfViewer = ({ pdfUrl, onClose, onLoad }) => {
//   const [numPages, setNumPages] = useState(null);

//   return (
//     <div
//       id="pdfDocument"
//       style={{
//         position: "relative",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center", // Center content horizontally
//         justifyContent: "center", // Center content vertically
//         width: "100%", // Full width of the parent container
//         height: "100%", // Full height of the parent container
//       }}
//     >
//       <button
//         style={{
//           position: "absolute",
//           top: "1rem",
//           right: "1rem",
//           zIndex: 1, // Ensure the button is above the PDF content
//         }}
//         onClick={onClose}
//       >
//         <FontAwesomeIcon icon={faX} />
//       </button>
//       <div
//         style={{
//           maxWidth: "80vw", // Limit width to 80% of viewport width
//           maxHeight: "90vh", // Limit height to 90% of viewport height
//           border: "none",
//           overflow: "auto",
//         }}
//       >
//         <Document
//           file={pdfUrl}
//           onLoadSuccess={({ numPages }) => setNumPages(numPages)}
//           renderMode="canvas"
//         >
//           {[...Array(numPages).keys()].map((pageNumber) => (
//             <Page key={pageNumber + 1} pageNumber={pageNumber + 1} />
//           ))}
//         </Document>
//       </div>
//     </div>
//   );
// };

export const PdfViewer = ({ pdfUrl, onClose, onLoad }) => {
  const [numPages, setNumPages] = useState(null);

  return (
    <div
      id="pdfDocument"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "80vw",
        height: "90vh",
        border: "none",
        overflow: "auto",
      }}
    >
      <button style={{ alignSelf: "flex-end" }} onClick={onClose}>
        <FontAwesomeIcon icon={faX} />
      </button>
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        renderMode="canvas"
      >
        {[...Array(numPages).keys()].map((pageNumber) => (
          <Page 
            key={pageNumber + 1} 
            pageNumber={pageNumber + 1} 
            scale={1.5}  
          />
        ))}
      </Document>
    </div>
  );
};